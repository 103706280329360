import { useParams } from "react-router-dom";
import { fetchHelper } from "./fetchHelper";
import { useEffect, useState } from 'react';
import Game from "./Game";


export function GameId(props) {
    const params = useParams();
    const region = params.region;
    const gameId = params.gameId;

    const [game, setGame] = useState(null);

    useEffect(async () => {
        if (!game) {
            const results = await fetchHelper('gameStats', {
                region,
                gameId
            })
            setGame(results.game);
        }
    }, []);     // <-- This empty array is important, makes useEffect only fire on mounting, NOT on updates


    const getPlayerPath = (player) => {
        return "/wp/" + region + '/' + encodeURIComponent(player);
    };


    const getBMPath = (player) => {
        return `/benchmark/${region}/${gameId}/${encodeURIComponent(player)}`;
    };


    return (
        <main>
            {(game) ? <Game game={game} region={region} getPlayerPath={getPlayerPath} getBMPath={getBMPath}/> : <>Fetching Game {region} / {gameId}</>}
        </main>
    );
}